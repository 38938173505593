import React, { useRef, useState } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import uploadImg from '../assets/img/Uploader.png'; // Adjust the path as necessary

const Register = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [arrival, setArrival] = useState(""); 
  const [userID, setUserID] = useState("");
  const [familyMembers, setFamilyMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedUserIDs, setUploadedUserIDs] = useState([]);
  const fileInputRef = useRef(null);

  const handleFamilyMemberChange = (index, value) => {
    const updatedMembers = [...familyMembers];
    updatedMembers[index] = value;
    setFamilyMembers(updatedMembers);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.warn('No file selected.');
      return;
    }

    if (file.type !== 'text/csv') {
      alert('Selected file is not a CSV.');
      return;
    }

    setLoading(true);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        const userIDs = [];
        const config = {
          headers: {
            "Content-Type": "application/json"
          }
        };

        for (const element of results.data) {
          const username = element["Employee:: First name"] + " " + element["Employee:: Surname"];
          const email = element["E-Mail"];
          const location = element["Location"];
          const arrival = element["Arrival"];
          let partner = "";
          if (element["Partner:: First name"] && element["Partner:: Surname"]) {
            partner = element["Partner:: First name"] + " " + element["Partner:: Surname"];
          }

          const children = [];
          for (let i = 1; i <= 4; i++) {
            const childFirstName = element[`Child ${i}:: First name`];
            const childSurname = element[`Child ${i}:: Surname`];
            if (childFirstName && childSurname) {
              children.push(childFirstName + " " + childSurname);
            }
          }

          const members = [];
          if (partner) {
            members.push(partner);
          }
          if (children.length > 0) {
            members.push(...children);
          }
          try {
            const { data } = await axios.post(`/api/register`, { username, email, members, location, arrival });
            userIDs.push(data);

          } catch (error) {
            console.error(`Error registering user ${element.Name}:`, error);
          }  

        }
        
        setUploadedUserIDs(userIDs);
        alert("Registration Successful for CSV Data");
        setLoading(false);
      },
      error: function (error) {
        console.error('Error parsing CSV:', error);
        setLoading(false);
      }
    });
  };

  const addFamilyMember = () => {
    setFamilyMembers([...familyMembers, '']);
  };

  const removeFamilyMember = (index) => {
    const updatedMembers = familyMembers.filter((_, i) => i !== index);
    setFamilyMembers(updatedMembers);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      if (!username.trim() || !email.trim()) {
        alert("Please fill all the fields for single ticket.");
        setLoading(false);
        return;
      }

      const { data } = await axios.post(`/api/register`, { username, email, members: familyMembers, location ,arrival }, config);
      
      setUserID(data);
      setFamilyMembers([]);
      alert("Registration Successful for Single Ticket");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleArrivalChange = (event) => {
    const selectedArrival = event.target.value;
    setArrival(selectedArrival);

    if (selectedArrival === "eigene Anreise") {
      setLocation(""); // Clear location if "eigene Anreise" is selected
    }
  };

  return (
    <div className="bg-light text-black col py-3" style={{ height: '100vh' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '20px 0',
          overflow: 'hidden',
        }}
        className="bg-light text-black"
      >
        <button
          style={{
            width: '50%',
            height: '40px',
            borderRadius: '1em',
            fontSize: '1.0em',
            fontWeight: 'bold',
            backgroundColor: 'black',
            color: 'white'
          }}
        >
          Generate Ticket
        </button>
      </div>
      <form
        className="container-fluid d-flex flex-column align-items-center justify-content-center bg-light text-black"
        style={{ minHeight: '500px', textAlign: 'center', width: '100%' }}
        onSubmit={handleSubmit}
      >
        {userID && <h1 className="display-6 text-info my-3">{userID}</h1>}
        {uploadedUserIDs.length > 0 && (
          <div className="mt-5">
            <h3>Uploaded User IDs:</h3>
            <ul>
              {uploadedUserIDs.map((id, index) => (
                <li key={index}>{id}</li>
              ))}
            </ul>
          </div>
        )}
        <input
          className="form-control w-50 my-2"
          type="text"
          placeholder="Enter name"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          className="form-control w-50 my-2"
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <select
          id="arrivalSelect"
          className="form-control w-50 my-2"
          value={arrival}
          onChange={handleArrivalChange}
        >
          <option value="" disabled>Select an Arrival</option>
          <option value="Reisebus ab Niederlassung">Reisebus ab Niederlassung</option>
          <option value="eigene Anreise">eigene Anreise</option>
        </select>
        {arrival === "Reisebus ab Niederlassung" && (
          <select
            id="locationSelect"
            className="form-control w-50 my-2"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          >
            <option value="" disabled>Select a location</option>
            <option value="Waldshut">Waldshut</option>
            <option value="Rheinfelden">Rheinfelden</option>
            <option value="Achern">Achern</option>
            <option value="Steißlingen">Steißlingen</option>
          </select>
        )}
        <div style={{ width: '50%' }}>
          {familyMembers.map((member, index) => (
            <div key={index} className="my-2">
              <input
                className="form-control my-2"
                type="text"
                placeholder="Member name"
                value={member}
                onChange={(e) => handleFamilyMemberChange(index, e.target.value)}
              />
              {familyMembers.length > 0 && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => removeFamilyMember(index)}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
        </div>
        <button
          type="button"
          className="btn btn-info my-3"
          onClick={addFamilyMember}
        >
          Add Family Member
        </button>
        <button type="submit" className="btn btn-info my-3" disabled={loading}>
          {loading ? "Please Wait..." : "Submit"}
        </button>
      </form>
      <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', marginBottom: '20px' }}>
        <img
          src={uploadImg}
          alt="Upload"
          style={{ width: '100px', height: '100px', cursor: 'pointer' }}
          onClick={() => fileInputRef.current.click()}
        />
      </div>
      <input
        type="file"
        accept=".csv"
        onChange={handleImageUpload}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default Register;
