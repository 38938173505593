import React, { useEffect, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import '../App.css'; // Adjust the path to where your CSS file is located

function Layout() {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    const menuBtn = document.querySelector("#menu-btn");
    const sidebar = document.querySelector("#sidebar");
    const container = document.querySelector(".my-container");

    const toggleSidebar = () => {
      sidebar.classList.toggle("active-nav");
      container.classList.toggle("active-cont");
    };

    menuBtn.addEventListener("click", toggleSidebar);

    // Cleanup event listener on component unmount
    return () => {
      menuBtn.removeEventListener("click", toggleSidebar);
    };
  }, []);

  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <div className="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column" id="sidebar">
          <ul className="nav flex-column text-white w-100">
            <a   onClick={() => navigate('/admin/dashboard')}
             className="nav-link h3 text-white my-2">
              Ticket Generator
            </a>
            <li className="nav-item">
              <a
                style={{ cursor: 'pointer' }}
                className="nav-link align-middle px-0"
                onClick={() => navigate('/admin/dashboard')}
              >
                <i className="bx bxs-dashboard"></i>
                <span className="mx-2">Home</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                style={{ cursor: 'pointer' }}
                className="nav-link align-middle px-0"
                onClick={() => navigate('/admin/ScannedList')}
                
              >
                <i className="bx bx-log-out"></i>
                <span className="mx-2">Scan Person List</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                style={{ cursor: 'pointer' }}
                className="nav-link align-middle px-0"
                onClick={logout}
              >
                <i className="bx bx-log-out"></i>
                <span className="mx-2">Sign out</span>
              </a>
            </li>
          </ul>

          <span className="nav-link h4 w-100 mb-5">
            <a href="#"><i className="bx bxl-instagram-alt text-white"></i></a>
            <a href="#"><i className="bx bxl-twitter px-2 text-white"></i></a>
            <a href="#"><i className="bx bxl-facebook text-white"></i></a>
          </span>
        </div>

        <div className="p-1 my-container active-cont">
          <nav className="navbar top-navbar navbar-light bg-light px-5">
            <a className="btn border-0" id="menu-btn"><i className="bx bx-menu"></i></a>
          </nav>
          <div className="content-area">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
