import React, { useState, useRef, useEffect, useCallback } from 'react';
import { QrReader } from 'react-qr-reader';
import axios from 'axios';
import jsQR from 'jsqr';
import uploadImg from '../assets/img/Uploader.png';
import Scanner from '../assets/img/scanner.gif';
import { BrowserView, MobileView } from 'react-device-detect';
import debounce from 'lodash/debounce';

const QrReaderComponent = () => {
    const [user, setUser] = useState(null); // State to store user details fetched from server
    const [error, setError] = useState(null); // State to store error messages
    const [loading, setLoading] = useState(false); // State to indicate loading status
    const [scanning, setScanning] = useState(false); // State to indicate if the scanner is active
    const fileInputRef = useRef(null); // Reference to the file input element
    const [lastScannedData, setLastScannedData] = useState(null); // State to store the last scanned QR code data

    // Handle image upload and process QR code from the uploaded image
    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    const code = jsQR(imageData.data, imageData.width, imageData.height);
                    if (code) {
                        fetchUserDetails(code.data); // Fetch user details for the QR code data
                    } else {
                        setError("No QR code found in the image."); // Set error if no QR code is found
                    }
                };
            };
            reader.readAsDataURL(file);
        }
    };

    // Fetch user details from the server using the scanned QR code data
    const fetchUserDetails = async (userID) => {
        if (loading || lastScannedData === userID) return; // Prevent multiple requests for the same ID or while loading

        setLoading(true);
        try {
            const { data } = await axios.get(`/api/getdetails/${userID}`); // Update with your actual API endpoint
            setUser(data);
            setError(null);
            setLastScannedData(userID); // Update last scanned data
        } catch (error) {
            console.error("Error fetching user details:", error);
            setError("Ticket nicht gefunden"); // Set error message if the user is not found
        }
        setLoading(false);
    };

    // Throttled version of fetchUserDetails to prevent multiple rapid requests
    const throttledFetchUserDetails = useCallback(
        debounce((userID) => {
            fetchUserDetails(userID);
        }, 1000),
        []
    );

    // Start the QR code scanning process
    const startScanning = () => {
        setUser(null);
        setError(null);
        setLastScannedData(null); // Reset the last scanned data
        setScanning(true); // Activate the scanner
    };

    // Stop the QR code scanning process
    const stopScanning = () => {
        setScanning(false); // Deactivate the scanner
    };

    // Stop scanning while loading data
    useEffect(() => {
        if (loading) {
            stopScanning();
        }
    }, [loading]);

    return (
        <div className="container-fluid d-flex flex-column align-items-center justify-content-center bg-light text-black" style={{ height: "100vh", overflow: "hidden", position: "relative" }}>
            {scanning ? (
                <div style={{ position: 'relative', width: '100%' }}>
                    <BrowserView>
                        <QrReader
                            constraints={{
                                facingMode: { exact: "user" } // Ensure the front camera is used for scanning
                            }}
                            delay={300}
                            onError={(error) => {
                                console.log(error);
                                setError(error.message); // Handle QR reader errors
                            }}
                            onResult={(result) => {
                                if (result?.text && result.text !== lastScannedData) {
                                    throttledFetchUserDetails(result.text); // Throttled fetching of user details for the QR code data
                                }
                            }}
                            style={{ width: "100%", height: "100%" }}
                        />
                    </BrowserView>
                    <MobileView>
                        <QrReader
                            constraints={{
                                facingMode: { exact: "environment" } // Ensure the back camera is used for scanning
                            }}
                            delay={300}
                            onError={(error) => {
                                console.log(error);
                                setError(error.message); // Handle QR reader errors
                            }}
                            onResult={(result) => {
                                if (result?.text && result.text !== lastScannedData) {
                                    throttledFetchUserDetails(result.text); // Throttled fetching of user details for the QR code data
                                }
                            }}
                            style={{ width: "100%", height: "100%" }}
                        />
                    </MobileView>
                    <img
                        src={Scanner}
                        alt="scanner"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            pointerEvents: 'none',
                            height: '20%'
                        }}
                    />
                </div>
            ) : (
                <button className="btn btn-success  " onClick={startScanning}>
                    {loading ? "Loading..." : "Scannen beginnen"} 
                </button>
            )}
            <div style={{ position: 'absolute', bottom: '20px', right: '20px', cursor: 'pointer' }}>
                <img src={uploadImg} alt="upload" style={{ width: '19%', height: '19%', float: 'right' }} onClick={() => fileInputRef.current.click()} />
            </div>
            <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={fileInputRef}
                style={{ display: 'none' }}
            />
            {error && (
                <div className="card position-absolute shadow-lg text-white bg-danger" style={{ bottom: '50px', left: '50%', transform: 'translateX(-50%)', maxWidth: '400px', textAlign: 'center' }}>
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-center mb-3">
                            <img src="https://img.icons8.com/color/48/000000/cancel--v1.png" alt="invalid ticket" style={{ marginRight: '10px' }} />
                            <h3 className="card-title text-white">Ungültig</h3>
                        </div>
                        <p className="card-text"><strong>{error}</strong></p>
                    </div>
                </div>
            )}
            {!error && user?.name &&
                <div className="card position-absolute shadow-lg" style={{ bottom: '50px', left: '50%', transform: 'translateX(-50%)', maxWidth: '400px', textAlign: 'center' }}>
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-center mb-3">
                            <img src="https://img.icons8.com/color/48/000000/ok--v1.png" alt="valid ticket" style={{ marginRight: '10px' }} />
                            <h3 className="card-title text-success">Gültiges Ticket</h3>
                        </div>
                        <p className="card-text"><strong>Dieses Ticket ist gültig. Informationen:</strong></p>
                        <div className="border-top my-2"></div>
                        <h4 className="card-text my-3">Name: <strong>{user.name}</strong></h4>
                        <button className="btn btn-secondary mt-3" onClick={startScanning}>
                        Weiteres Ticket scannen
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

export default QrReaderComponent;
