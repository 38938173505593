import React, { createContext, useState, useEffect } from 'react';
//import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ token: null, role: null });
  const navigate = useNavigate();

  useEffect(() => {
    const savedAuth = JSON.parse(localStorage.getItem('auth'));
    if (savedAuth) {
      setAuth(savedAuth);
    }
  }, []);

  const login = (token, role) => {
    setAuth({ token, role });
    localStorage.setItem('auth', JSON.stringify({ token, role }));
    navigate('/admin/dashboard');
  };

  const logout = () => {
    setAuth({ token: null, role: null });
    localStorage.removeItem('auth');
    navigate('/');
  };

  return (
    <AuthContext.Provider value={{ auth,setAuth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
