import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';

const ScannedList = () => {
    const [scannedPersons, setScannedPersons] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/api/scannedPerson');
                setScannedPersons(response.data);
            } catch (error) {
                console.error('Error fetching scanned persons:', error);
            }
        };

        fetchData();
    }, []);

    const filteredPersons = scannedPersons.filter(person =>
        (person.name && person.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (person.username && person.username.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const displayUsers = filteredPersons
        .slice(pagesVisited, pagesVisited + usersPerPage)
        .map((person) => (
            <li key={person._id || person.name} className="list-group-item d-flex justify-content-between align-items-center">
                <div>
                    <span>{person.username || person.name}</span>
                    <br />
                    <small className="text-muted">Scanned Date: {new Date(person.Scaned_at).toLocaleString()}</small>
                </div>
            </li>
        ));

    const pageCount = Math.ceil(filteredPersons.length / usersPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setPageNumber(0); // Reset page number when search term changes
    };

    const exportToCSV = () => {
        const csvData = filteredPersons.map(person => ({
            ID:person._id,
            Naame: person.username || person.name,
            ScannedDate: new Date(person.Scaned_at).toLocaleString() || ''
        }));

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.href = url;
        link.setAttribute('download', 'scanned_persons.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="container-fluid d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '100vh', width: 'auto' }}>
            <h2 className="my-4">Scanned Persons List</h2>
            <input
                type="search"
                style={{ maxWidth: '600px', width: '90%', margin: '10px' }}
                className='list-group-item d-flex justify-content-between align-items-center'
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch}
            />
            <ul className="list-group mb-4" style={{ maxWidth: '600px', width: '90%' }}>
                {displayUsers}
                {filteredPersons.length === 0 && (
                    <li className="list-group-item text-center">No scanned persons found.</li>
                )}
            </ul>
            <button onClick={exportToCSV} className="btn btn-primary mb-4">
                Export to CSV
            </button>
            <nav>
                <ul className="pagination justify-content-center">
                    <li className={`page-item ${pageNumber === 0 && 'disabled'}`}>
                        <button className="page-link" onClick={() => changePage({ selected: pageNumber - 1 })}>
                            Previous
                        </button>
                    </li>
                    {Array.from({ length: pageCount }, (_, index) => (
                        <li key={index} className={`page-item ${pageNumber === index && 'active'}`}>
                            <button className="page-link" onClick={() => setPageNumber(index)}>
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${pageNumber === pageCount - 1 && 'disabled'}`}>
                        <button className="page-link" onClick={() => changePage({ selected: pageNumber + 1 })}>
                            Next
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default ScannedList;
