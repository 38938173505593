import React, { useContext, useState } from 'react'
import axios from 'axios';
import { AuthContext } from './AuthContext';
import sideImg  from '../assets/img/4957136.jpg'
import logo  from '../assets/img/logo.jpg'


function AdminLogin() {

    const { login } = useContext(AuthContext);
    const [admin, setadmin] = useState({
        email:"",
        password:""
    })
  

    const handleLogin = async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json"
            }
          };
          const { data } = await axios.post(`/api/admin-login`, admin, config);
          console.log(data);
          login(data.token, data.role);
        } catch (error) {
          if (error.response && error.response.status === 400) {
            alert(error.response.data.msg);
          } else {
            console.error("An unexpected error occurred:", error.message);
          }
        }
      };

  return (
    <section className="bg-light p-3 p-md-4 p-xl-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-xxl-11">
          <div className="card border-light-subtle shadow-sm">
            <div className="row g-0">
              <div className="col-12 col-md-6">
                <img
                  className="img-fluid rounded-start w-100 h-100 object-fit-cover"
                  loading="lazy"
                  src={sideImg}
                  alt="Welcome back you've been missed!"
                />
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
                <div className="col-12 col-lg-11 col-xl-10">
                  <div className="card-body p-3 p-md-4 p-xl-5">
                    <div className="row">
                      <div className="col-12">
                        <div className="mb-5">
                          <div className="text-center mb-4">
                            <a href="#!">
                              <img
                                src={logo}
                                alt="Logo"
                                width={175}
                                height={175}
                              />
                            </a>
                          </div>
                          <h4 className="text-center">
                            Welcome back you've been missed!
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex gap-3 flex-column">
                      
                        </div>
                       
                      </div>
                    </div>
                    <div  >
                      <div className="row gy-3 overflow-hidden">
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              placeholder="name@example.com"
                              value={admin.email}
                              onChange={(e)=>{
                                setadmin({...admin,email:e.target.value})
                              }}
                            />
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              id="password"
                              placeholder="Password"
                              value={admin.password}
                              onChange={(e)=>{
                                setadmin({...admin,password:e.target.value})
                              }}
                            />
                            <label htmlFor="password" className="form-label">
                              Password
                            </label>
                          </div>
                        </div>
                        <div className="col-12">
                        </div>
                        <div className="col-12">
                          <div className="d-grid">
                            <button className="btn btn-dark btn-lg" onClick={handleLogin}>
                              Log in now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-center mt-5">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  )
}

export default AdminLogin