import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, Outlet, Navigate } from 'react-router-dom';
import Register from './components/Register';
import QrGenerator from './components/QrGenerator';
import QrReader from './components/QrReader';
import AdminLogin from './components/AdminLogin';
import { AuthProvider, AuthContext } from './components/AuthContext';
import Layout from './components/Layout';


const PrivateRoute = ({ element: Element }) => {

  let location = window.location.href.split("/admin")[1]

  const [auth, setAuth] = useState(localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth')) || '');
  
  switch(location) {
    case '/dashboard':
      return auth.token && auth.role == 'admin' ? <Register /> : <Navigate to="/" />;
      break;
    case '/generator':
      return auth.token && auth.role == 'admin' ? <QrGenerator /> : <Navigate to="/" />;
      break;
      case '/ScannedList':
        return auth.token && auth.role == 'admin' ? <QrGenerator /> : <Navigate to="/" />;
        break;
    default:
      return auth.token && auth.role == 'admin' ? <Outlet /> : <Navigate to="/" />;
      break;
  }
  

};

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route exact path="/" element={<QrReader />} />
          <Route exact path="/admin" element={<AdminLogin />} />
          <Route element={<Layout />}>
            <Route exact path="/admin/dashboard" element={<PrivateRoute />} />
            <Route exact path="/admin/ScannedList" element={<PrivateRoute />} />
            <Route exact path="/admin/generator" element={<PrivateRoute />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
